import React from 'react'
import PropTypes from 'prop-types'

export const Content = ({ style, htmlContent, content, className }) => {
  if(htmlContent)
    return <div style={{marginTop: "15px", marginBottom: "20px", ...style}} className={className} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  else
    return <div style={{marginTop: "15px", marginBottom: "20px", ...style}} className={className}>{content}</div>
}

Content.propTypes = {
  content: PropTypes.node,
  htmlContent: PropTypes.node,
  className: PropTypes.string,
}

export default Content
