import React from "react"

const Sidebar = ({carrier}) => {
  return (
    <div className="services-details-info">

      <div className="services-contact-info">
        <h3 style={{borderBottom: "1px solid #eeeeee"}}>Infoboard</h3>

        <ul>
          {carrier.frontmatter.carrier_website !== null &&
            carrier.frontmatter.carrier_website !== "" &&
          <li>
            <div className="icon" style={{top: "0"}}>
              <i className="bx bx-world"/>
            </div>
            <span>Homepage:</span>
            <a href={carrier.frontmatter.carrier_website}>{carrier.frontmatter.carrier_website}</a>
          </li>
          }
          {carrier.frontmatter.carrier_tracktraceurl !== null &&
          carrier.frontmatter.carrier_tracktraceurl !== "" &&
          <li>
            <div className="icon" style={{ top: "0" }}>
              <i className="bx bx-search-alt" />
            </div>
            <span>Tracking URL:</span>
            <a href={carrier.frontmatter.carrier_tracktraceurl}
               style={{ wordWrap: "break-word" }}>{carrier.frontmatter.carrier_tracktraceurl}</a>
          </li>
          }
          {carrier.frontmatter.carrier_phone_hotline !== null &&
          carrier.frontmatter.carrier_phone_hotline !== "" &&
          <li>
            <div className="icon" style={{ top: "0" }}>
              <i className="bx bx-phone" />
            </div>
            <span>Hotline:</span>
            <a href={"tel:" + carrier.frontmatter.carrier_phone_hotline}>{carrier.frontmatter.carrier_phone_hotline}</a>
          </li>
          }
          {carrier.frontmatter.carrier_support_email !== null &&
          carrier.frontmatter.carrier_support_email !== "" &&
          <li>
            <div className="icon" style={{ top: "0" }}>
              <i className="bx bx-envelope" />
            </div>
            <span>Support Email:</span>
            <a
              href={"mailto:" + carrier.frontmatter.carrier_support_email}>{carrier.frontmatter.carrier_support_email}</a>
          </li>
          }
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
