import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Sidebar from "../components/carrier/Sidebar"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Content from "../components/App/Content"
import Seo from "../components/App/Seo"

const CarrierPage = ({ data: { markdownRemark: carrier } }) => {
  return (
    <Layout>
      <Seo title={"NETVERSYS - Die " + carrier.frontmatter.carrier_name + " Versandsoftware."}
           description={"NETVERSYS bietet eine Vielzahl von Carrier-Integrationen. Entdecken Sie die Fähigkeiten von " +
           "NETVERSYS im Zusammenspiel mit " + carrier.frontmatter.carrier_name}
           image={`/images/og/Versandsoftware-fuer-${carrier.fields.slug.replaceAll("/", "")}-og.png`}
      />
      <Navbar />
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-desc" style={{textAlign: "left"}}>
                <span className="sub-title">Carriermodul in NETVERSYS</span>

                <div className={"d-flex"}>
                  <div style={{flexGrow: "1" }}>
                    <h1 className={"pt-3"}>{carrier.frontmatter.carrier_name}</h1>
                    <span className={"badge badge-primary"}>{carrier.frontmatter.carrier_type}</span>
                  </div>
                  <div style={{ alignSelf: "flex-start" }}>
                    <GatsbyImage image={getImage(carrier.frontmatter.carrier_logo)}
                                 alt={carrier.frontmatter.carrier_name + " Logo"} />
                  </div>
                </div>

                <Content style={{ textAlign: "justify", hyphens: "auto" }} htmlContent={carrier.html} />

                <h2>{carrier.frontmatter.carrier_name} Produkte und Services in NETVERSYS</h2>
                <Row className="align-items-center">
                  <Col xs={12} md={6}>
                    <div className="content">
                      <ul>
                        {carrier.frontmatter.products.map((product) => (
                          <li key={product}>{product}</li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar carrier={carrier} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export default CarrierPage

export const pageQuery = graphql`
  query carrierById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        carrier_name
        products
        services
        carrier_website
        carrier_tracktraceurl
        carrier_type
        carrier_support_email
        carrier_phone_hotline
        carrier_logo {
          childImageSharp {
            gatsbyImageData(width: 180, placeholder: BLURRED, quality: 100)
          }
        }
      }
      fields {
        slug
        collection
      }
      html
    }
  }
`
